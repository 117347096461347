@charset "utf-8";
@import "../../../modules/import";

/*
 * opacity
 */
.u-op100 { opacity: 1 !important; }
@for $i from 0 through 9 {
  .u-op0#{$i} { opacity: 1 }
  .u-op0#{$i}:hover,.u-op0#{$i}:active { opacity: $i / 10 }
}