@charset "utf-8";
@import "../../../modules/import";

/*
 * font-style
 */
.u-fw-n  { font-weight: normal !important; }
.u-fw-b  { font-weight: bold !important; }
.u-fw-br { font-weight: bolder !important; }
.u-fw-lr { font-weight: lighter !important; }
.u-fw-i  { font-weight: inherit !important; }
.u-fw100 { font-weight: 100 !important; }
.u-fw200 { font-weight: 200 !important; }
.u-fw300 { font-weight: 300 !important; }
.u-fw400 { font-weight: 400 !important; }
.u-fw500 { font-weight: 500 !important; }
.u-fw600 { font-weight: 600 !important; }
.u-fw700 { font-weight: 700 !important; }
.u-fw800 { font-weight: 800 !important; }
.u-fw900 { font-weight: 900 !important; }
.u-fs-n  { font-style: normal !important; }
.u-fs-o  { font-style: oblique !important; }
.u-fs-i  { font-style: italic !important; }