@charset "utf-8";
@import "../../../modules/import";

/*
 * position
 */
.u-pos-st { position: static; }
.u-pos-rl { position: relative; }
.u-pos-ab { position: absolute; }
.u-pos-fx { position: fixed; }
.u-pos-si { position: sticky; }
.u-pos-ih { position: inherit; }
.u-pos-in { position: initial; }
.u-pos-un { position: unset; }