@charset "utf-8";
@import "../../../modules/import";

/*
 * padding
 */
@for $i from 0 through 10 {
  $spacing: 5;
  .u-pt#{$i * $spacing} { padding-top:    (1px * $spacing) * $i !important; }
  .u-pr#{$i * $spacing} { padding-right:  (1px * $spacing) * $i !important; }
  .u-pb#{$i * $spacing} { padding-bottom: (1px * $spacing) * $i !important; }
  .u-pl#{$i * $spacing} { padding-left:   (1px * $spacing) * $i !important; }
  .u-pv#{$i * $spacing} { padding-top:    (1px * $spacing) * $i !important; padding-bottom: (1px * $spacing) * $i !important; }
  .u-ph#{$i * $spacing} { padding-right:  (1px * $spacing) * $i !important; padding-left:   (1px * $spacing) * $i !important; }
  .u-pa#{$i * $spacing} { padding:        (1px * $spacing) * $i !important; }
}