@charset "utf-8";
@import "../../../modules/import";

/*
 * margin
 */
@for $i from 0 through 10 {
  $spacing: 5;
  .u-mt#{$i * $spacing} { margin-top:     (1px * $spacing) * $i !important; }
  .u-mr#{$i * $spacing} { margin-right:   (1px * $spacing) * $i !important; }
  .u-mb#{$i * $spacing} { margin-bottom:  (1px * $spacing) * $i !important; }
  .u-ml#{$i * $spacing} { margin-left:    (1px * $spacing) * $i !important; }
}