@charset "UTF-8";
/* -----------------------------------------------
/* bootstrap-grid
-------------------------------------------------- */
@import "share/_bootstrap-grid";

/* -----------------------------------------------
/* for Voice Browser
-------------------------------------------------- */
.hidden {position: absolute; overflow:hidden;width: 0;height: 0;margin: 0;padding: 0;}

/* -----------------------------------------------
/* float
-------------------------------------------------- */
@import "share/_float";

/* -----------------------------------------------
/* display
-------------------------------------------------- */
@import "share/_display";

/* -----------------------------------------------
/* flex
-------------------------------------------------- */
@import "share/_flex";

/* -----------------------------------------------
/* position
-------------------------------------------------- */
@import "share/_position";

/* -----------------------------------------------
/* opacity
-------------------------------------------------- */
@import "share/_opacity";

/* -----------------------------------------------
/* margin - t,r,b,l
-------------------------------------------------- */
@import "share/_margin";

/* -----------------------------------------------
/* padding - t,r,b,l,v,h
-------------------------------------------------- */
@import "share/_padding";


/* -----------------------------------------------
/* color
-------------------------------------------------- */
@import "share/_color";

/* -----------------------------------------------
/* font-size
-------------------------------------------------- */
@import "share/_font-size";

/* -----------------------------------------------
/* font-style
-------------------------------------------------- */
@import "share/_font-style";

/* -----------------------------------------------
/* text-align
-------------------------------------------------- */
@import "share/_text-align";

/* -----------------------------------------------
/* line-height
-------------------------------------------------- */
@import "share/_line-height";

/* -----------------------------------------------
/* word-break
-------------------------------------------------- */
@import "share/_word-break";

/* -----------------------------------------------
/* link
-------------------------------------------------- */
@import "share/_link";

/* -----------------------------------------------
/* list
-------------------------------------------------- */
@import "share/_list";

/* -----------------------------------------------
/* width
-------------------------------------------------- */
@import "share/_width";


@import "_vendor";