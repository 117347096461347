@charset "utf-8";
@import "../../../modules/import";

/*
 * width
 */
 @for $i from 0 through 20 {
  $spacing: 5;
  .u-w#{$i * $spacing}p { width: (1% * $spacing) * $i !important; }
}
.u-mw100p { max-width: 100% !important; }
