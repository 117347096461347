@charset "utf-8";
@import "_variable";

// ==================================================
// media query
// ==================================================
@mixin media($media-bp) {
    @if $media-bp == xs {
        @media (max-width: $screen-xs-max) {
            @content;
        }
    }
    @else if $media-bp == sm {
        @media (min-width: $screen-sm-min) {
            @content;
        }
    }
    @else if $media-bp == md {
        @media (min-width: $screen-md-min) {
            @content;
        }
    }
    @else if $media-bp == lg {
        @media (min-width: $screen-lg-min) {
            @content;
        }
    }
    @else if $media-bp == xl {
        @media (min-width: $screen-xl-min) {
            @content;
        }
    }
    @else if $media-bp == print {
        @media print {
            @content;
        }
    }

    @else if $media-bp == sm-max {
        @media (max-width: $screen-sm-max) {
            @content;
        }
    }
    @else if $media-bp == md-max {
        @media (max-width: $screen-md-max) {
            @content;
        }
    }
    @else if $media-bp == lg-max {
        @media (max-width: $screen-lg-max) {
            @content;
        }
    }
}

@mixin fontAwesome {
  //font-family: 'Font Awesome 5 Free';
  font-family: 'FontAwesome';
  -webkit-font-smoothing: antialiased;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-rendering: auto;
  line-height: 1;
}
