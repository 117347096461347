@charset "utf-8";
@import "../../../modules/import";

/*
 * color
 */
.u-fc-white  { color: #fff !important; }
.u-fc-red    { color: #f00 !important; }
.u-fc-cred   { color: #c00 !important; }
.u-fc-dred   { color: #d00 !important; }
.u-fc-9red   { color: #900 !important; }
.u-fc-black  { color: #111 !important; }
.u-fc-dark   { color: #666 !important; }
.u-fc-gray   { color: #999 !important; }
.u-fc-blue   { color: #006 !important; }