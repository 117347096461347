@charset "utf-8";
@import "../../../modules/import";

/*
 * list
 */
li img, li a, li a img { vertical-align: bottom; }
.u-list-d  { padding-left: 1.5em; } .u-list-d>*  { list-style-type: disc; }
.u-list-c  { padding-left: 1.5em; } .u-list-c>*  { list-style-type: circle; }
.u-list-s  { padding-left: 1.5em; } .u-list-s>*  { list-style-type: square; }
.u-list-dc { padding-left: 1.5em; } .u-list-dc>* { list-style-type: decimal; }
.u-list-la { padding-left: 1.8em; } .u-list-la>* { list-style-type: lower-alpha; }
.u-list-at { padding-left: 1em;   } .u-list-at>* { text-indent: -1em; }
.u-list-x1 { padding-left: 1.2em; } .u-list-x1>* { text-indent: -1.2em; }