@charset "utf-8";
@import "../../../modules/import";

/*
 * font-size
 */
@for $i from 8 through 48 {
  .u-fs#{$i} { font-size: $i * .1rem !important; }
}
.u-fs {
  &__xxs { font-size: xx-small; }
  &__xs  { font-size:  x-small; }
  &__s   { font-size:    small; }
  &__m   { font-size:   medium; }
  &__l   { font-size:    large; }
  &__xl  { font-size:  x-large; }
  &__xxl { font-size: xx-large; }
}
@for $i from 0 through 9 {
  $spacing: $i * -0.1em ;
  $tmp: "0#{$i}";
  .u-tim#{$tmp} { font-size: $spacing !important; }
}
@for $i from 10 through 20 {
  $spacing: $i * -0.1em ;
  .u-tim#{$i}   { font-size: $spacing !important; }
}