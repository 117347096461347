@charset "utf-8";
@import "../../../modules/import";

/*
 * text-decoration
 */
.u-txd-n { text-decoration: none !important; }
.u-txd-o { text-decoration: overline !important; }
.u-txd-u { text-decoration: underline !important; }
.u-txd-l { text-decoration: line-through !important; }
.u-txd-b { text-decoration: blink !important; }