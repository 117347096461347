@charset "utf-8";
@import "../../../modules/import";

/*
 * work-break
 */
.u-wobr-n  { word-break: normal !important; }
.u-wobr-k  { word-break: keep-all !important; word-wrap: normal !important; }
.u-wobr-ba { word-break: break-all !important; word-wrap: break-word !important; }
.u-whsp-n  { white-space: normal !important; }
.u-whsp-p  { white-space: pre !important; }
.u-whsp-nw { white-space: nowrap !important; }